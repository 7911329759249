import React from 'react'
import './MarqueeComponent.css'
const MarqueeComponent = () => {
    return (
        <div>
            <marquee behavior="scroll" direction="left">
                <p  >Bachpan School Timing 8:00 AM to 2:00 PM | Contact: +91-7665555533, +9-18239000200 | A Premier School Offering Quality Education and Holistic Development for Your Child!</p>
            </marquee>
        </div>
    )
}

export default MarqueeComponent
